import { css, keyframes } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Secondary, States, DesktopSize, Primary } from '../config/constants';

const bounceAnimation = keyframes({
  '0%, 100%': { transform: 'scale3d(1, 1, 1)', color: 'white' },
  '30%': { transform: 'scale3d(1.25, 0.75, 1)' },
  '40%': { transform: 'scale3d(0.75, 1.25, 1)', color: Secondary },
  '50%': { transform: 'scale3d(1.15, 0.85, 1)' },
  '65%': { transform: 'scale3d(.95, 1.05, 1)' },
  '75%': { transform: 'scale3d(1.05, .95, 1)' },
});
const flyinAnimation = keyframes({ '0%': { transform: 'translateY(-100vh)' }, '100%': { transform: 'translateY(0)' } });
const fadeIn = keyframes({ '0%': { opacity: 0 }, '100%': { opacity: 1 } });
const container = css({ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column' });
const header = css({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  flex: 1,
  alignItems: 'center',
  padding: '0% 5% 0 5%',
});
const logo = css({ flex: 1, img: { width: 'max(6.25vw, 7vh)' } });
const icon = css({
  position: 'relative',
  zIndex: 2,
  marginRight: '3%',
  '&:hover': { filter: `drop-shadow(0 0 .5vw ${Secondary})` },
  width: '2.4vw',
});
const textContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 4.25,
  marginTop: '-2vw',
  color: 'white',
});
const letterC = css({
  width: '5.25vh',
  [DesktopSize]: { width: '7.25vw' },
  animation: `${fadeIn} 2.5s`,
  animationTimingFunction: 'ease-in',
});
const letterCss = flyinLetters =>
  css({
    transform: flyinLetters ? 'translateY(0)' : 'translateY(-100vh)',
    fontSize: '6.75vh',
    [DesktopSize]: { fontSize: '9vw' },
    fontWeight: 700,
    position: 'relative',
    zIndex: 2,
    display: 'inline-block',
  });
const separator = css({ opacity: 0.15, flex: 1 });
const line = css({ width: '50%', textDecoration: 'line-through' });
const about = css({ width: '50%', flex: 3.5, transition: 'color 7s', fontSize: '1.25vw' });
const aboutHidden = css({ color: Primary });
const aboutShown = css({ color: 'white' });
const options = css({
  flex: 4,
  display: 'flex',
  flexDirection: 'row',
  width: '30%',
  justifyContent: 'space-around',
});
const optionItem = css({
  zIndex: 2,
  height: 0,
  '&:hover': { textDecoration: 'underline' },
  alignItems: 'flex-start',
  color: Secondary,
  fontWeight: 'bold',
  fontSize: '2vw',
});
const bounceCss = css({ animation: `${bounceAnimation} 1s` });
const flyinCss = delay =>
  css({
    animation: `${flyinAnimation} 0.5s`,
    animationDelay: `${delay}s`,
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
  });

const scrollTo = event => document.getElementById(event.target.innerText).scrollIntoView();
const greeting = `Hello,I'maciano,`.split('');

export const Intro = () => {
  const [flyinLetters, toggleFlyinLetters] = useState({ cells: greeting.map(() => false), status: false });
  const [bounceLetters, toggleBounceLetters] = useState(greeting.map(() => false));

  const animate = (index, boolean) => {
    if (!flyinLetters.cells[index])
      toggleFlyinLetters(prev => ({
        cells: toggleArrayBoolean(prev.cells, index, true),
        status: index === 15 ? true : false,
      }));
    if (greeting[index] !== ',' && greeting[index] !== `'` && flyinLetters.status)
      toggleBounceLetters(prev => toggleArrayBoolean(prev, index, boolean));
  };

  return (
    <div id="Intro" css={container}>
      <header css={header}>
        <div css={logo}>
          <img src="/logo.png" loading="eager" />
        </div>
        <img css={icon} src="/github.png" loading="eager" />
        <img css={icon} src="/linkedin.png" loading="eager" />
      </header>
      <div css={textContainer}>
        <div>
          {greeting.map((char, index) => {
            const letter = letterCss(flyinLetters.cells[index]);
            const flyin = !flyinLetters.cells[index] ? flyinCss(index * 0.1) : null;
            const bounce = bounceLetters[index] ? bounceCss : null;
            return (
              <React.Fragment key={index}>
                <span
                  css={[letter, flyin, bounce]}
                  onMouseEnter={() => animate(index, true)}
                  onAnimationEnd={() => animate(index, false)}
                >
                  {char}
                </span>
                {index === 5 && <br />}
                {index === 8 && (
                  <>
                    &emsp;&emsp;
                    {<StaticImage css={letterC} src="../images/C.png" placeholder="none" loading="eager" alt="" />}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div css={separator}>
          <span css={line}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
          <span>&emsp;Web Developer&emsp;</span>
          <span css={line}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
        </div>
        <div css={[about, flyinLetters.status ? aboutShown : aboutHidden]}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industries
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
          make a type specimen book.e
        </div>
        <div css={options}>
          <span css={optionItem} onClick={scrollTo}>
            {States.Skills}
          </span>
          <span css={optionItem} onClick={scrollTo}>
            {States.Projects}
          </span>
          <span css={optionItem} onClick={scrollTo}>
            {States.Contact}
          </span>
        </div>
      </div>
    </div>
  );
};

function toggleArrayBoolean(array, index, boolean) {
  const newArray = [...array];
  newArray[index] = boolean;
  return newArray;
}
